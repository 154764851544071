import { useEffect, useState } from "react";
import * as Yup from "yup";
import moment from "moment";
import {
  getProvincesRequest,
  getNationalityRequest,
  getCountriesRequest,
  getMaritalStatusRequest,
  getProvincesMunicipalitiesRequest,
  getUserByBiRequest,
} from "../../../../store/reducers/users/actionCreators";
import { getEntitiesRequest } from "../../../../store/reducers/admin/actionCreators";
import { useDispatch, useSelector } from "react-redux";
import PublicAgentSchema from "../../../../schemas/Employees/PublicAgent";
import ForeignSchema from "../../../../schemas/Employees/Foreign";

import { getDisabilityTypesRequest } from "../../../../store/reducers/users/actionCreators";


const InfoPessoaisMetaForm = (
  typeEmployee,
  provinceId,
  provinceOfBirth,
  disabledFields = [],
  employeeType,
  employeeData,
  isAdmin,
  setHideD,
  setHideDM
) => {
  const hideEntity = !isAdmin;

  const dispatch = useDispatch();

  const isNational = employeeType === "NATIONAL";

  // const [hideResidence, setHideResidence] = useState(false);
  const [valueIndicative, setValueIndicative] = useState("");

  const provinces = useSelector((state) => state.usersReducer.provinces);

  const municipalities = useSelector(
    (state) => state.usersReducer.municipalities
  );

  const disabilityTypes = useSelector(
    (state) => state.usersReducer.disabilityTypes
  );

  useEffect(async () => {
    dispatch(getDisabilityTypesRequest());
  }, []);
  
  const maritalStatus = useSelector(
    (state) => state.usersReducer.maritalStatus
  );

  const institution = useSelector(
    (state) => state.adminReducer.entityItems.content
  );

  useEffect(() => {
    if (!employeeType) return;
    setInfoPessoaisValidation(getEmployeeValidation(employeeType));
    if (employeeType === "NATIONAL") {
      setPersonalDataCol1(personalNationalDataCol1);
      setPersonalDataCol2(personalNationalDataCol2);
    } else {
      setPersonalDataCol1(personalForeignDataCol1);
      setPersonalDataCol2(personalForeignDataCol2);
    }
  }, [
    employeeType,
    provinces,
    institution,
    maritalStatus,
    // municipalities,
    // hideResidence,
  ]);


  // useEffect(() => {
  //   console.log("DD - ", employeeData?.countryResidence?.name)
  //   if (
  //     employeeData &&
  //     employeeData?.countryResidence?.name?.toLowerCase() === "angola"
  //   ) {
  //     setHideResidence(false);
  //   } else {
  //     setHideResidence(true);
  //   }
  // }, [employeeData]);
  
  useEffect(async () => {
    const province = provinces.find(
      (prov) => prov.name.toLowerCase() === provinceOfBirth?.toLowerCase()
    );
    if (province?.id) {
      dispatch(getProvincesMunicipalitiesRequest({ provinceId: province.id }));
    }
  }, [provinceOfBirth, provinces]);

  useEffect(async () => {
    dispatch(getProvincesRequest());
    dispatch(getNationalityRequest());
    dispatch(getCountriesRequest());
    dispatch(getMaritalStatusRequest());

    dispatch(
      getEntitiesRequest({
        queryDslFilters: [
          { label: "status", value: "ACTIVE" },
          { label: "size", value: 1000 },
        ],
        entity: "institutions",
      })
    );
  }, []);

  const getMunByProv = (prov) => {
    const province = provinces.find((item) => item.name === prov);

    if (province?.id)
      dispatch(getProvincesMunicipalitiesRequest({ provinceId: province?.id }));
  };

  const getEmployeeValidation = (employeeType) => {
    if (employeeType === "FOREIGN") return ForeignSchema;
    return PublicAgentSchema;
  };

  const fixEntityStructure = (entityArray) => {
    return entityArray?.map((item) => {
      return {
        name: item.name,
      };
    });
  };

  const onBiChange = (value) => {
    const biSchema = Yup.object().shape({
      bi: Yup.string().test(
        "isLengthValid",
        "O BI deve ter 9 dígitos 2 letras e mais 3 dígitos",
        (val) => {
          const pattern = new RegExp(/^\d{9}[a-zA-Z]{2}\d{3}$/, "gm");
          return val.length === 14 && pattern.test(val);
        }
      ),
    });

    biSchema
      .validate(
        { bi: value },
        {
          abortEarly: false,
        }
      )
      .then((submitData) => {
        dispatch(getUserByBiRequest(submitData));
      })
      .catch((error) => {});
  };

  const profilePicFieldMeta = [
    {
      field: "image",
      label: "Foto de Perfil",
      type: "image",
      class: "mask-circle",
    },
  ];

  const nameFieldMeta = [
    {
      field: "name",
      label: "Nome",
      type: "text",
    },
  ];

  const [personalForeignDataCol1, setPersonalForeignDataCol1] = useState([
    {
      field: "foreignNationalityIdCard",
      label: "Tipo de Documento",
      type: "select",
      options: [
        { name: "Cartão de Residência", cod: "RESIDENCE_CARD" },
        { name: "Visto de trabalho", cod: "WORK_VISA" },
        { name: "Passaporte", cod: "PASSPORT" },
      ],
      fieldId: "foreignNationalityIdCard.cod",
      disabled: disabledFields.includes("foreignNationalityIdCard"),
      isMandatory: true,
    },
    {
      field: "name",
      label: "Nome",
      type: "text",
      disabled: disabledFields.includes("name"),
      isMandatory: true,
    },
    {
      field: "phone",
      label: "Telefone",
      type: "text",
      disabled: disabledFields.includes("phone"),
      isMandatory: true,
    },
    {
      field: "genre",
      label: "Género",
      type: "select",
      options: [
        { name: "Masculino", cod: "MALE" },
        { name: "Feminino", cod: "FEMALE" },
      ],
      fieldId: "genre.cod",
      disabled: disabledFields.includes("genre"),
      isMandatory: true,
    },
    {
      field: "countryResidence.name",
      fieldId: "countryResidence.id",
      label: "País de Residência",
      type: "auto-complete",
      alloptionscode: "/countries",
      optionscode: "/countries/search",
     
      disabled: disabledFields.includes("countryResidence.name"),
      isMandatory: true,
    },
    {
      field: "nif",
      label: "NIF",
      type: "text",
      disabled:
        ["ABROAD_COLLEGE_STUDENT", "WORKING_ABROAD"].includes(typeEmployee) ||
        disabledFields.includes("nif"),
      isMandatory: true,
    },
    {
      field: "agentNumber",
      label: "Nº de Agente",
      type: "number",
      disabled:
        ["ABROAD_COLLEGE_STUDENT", "WORKING_ABROAD", "PRIVATE_SECTOR"].includes(
          typeEmployee
        ) || disabledFields.includes("agentNumber"),
    },
    {
      field: "inspectionId.name",
      label: "Entidade",
      type: "select",
      options: institution,
      fieldId: "inspectionId.id",
      disabled: disabledFields.includes("inspectionId"),
      hide: hideEntity,
      isMandatory: true,
    },
  ]);

  const [personalForeignDataCol2, setPersonalForeignDataCol2] = useState([
    {
      field: "bi",
      label: "Número do documento",
      type: "text",
      onChange: (val) => {
        onBiChange(val);
      },
      disabled: disabledFields.includes("bi"),
      isMandatory: true,
    },
    {
      field: "birthDate",
      label: "Data de Nascimento",
      type: "date",
      placeholder: "dd-MM-yyyy",
      dateFormat: "dd-MM-yyyy",
      disabled: disabledFields.includes("birthDate"),
      isMandatory: true,
    },
    {
      field: "email",
      label: "Email",
      type: "email",
      disabled: disabledFields.includes("email"),
    },

    {
      field: "nationality.name",
      fieldId: "nationality.id",
      label: "Nacionalidade",
      type: "auto-complete",
      alloptionscode: "/nationalities",
      optionscode: "/nationalities/search",
      disabled: disabledFields.includes("nationality.name"),
      isMandatory: true,
    },
    {
      field: "address",
      label: "Morada",
      type: "text",
      disabled: disabledFields.includes("address"),
    },
    {
      field: "maritalStatus",
      label: "Estado Civil",
      type: "select",
      options: [
        { cod: "SINGLE", name: "Solteiro(a)" },
        { cod: "MARRIED", name: "Casado(a)" },
        { cod: "DIVORCED", name: "Divorciado(a)" },
        { cod: "WIDOW", name: "Viúvo(a)" },
      ],
      fieldId: "maritalStatus.cod",
      disabled: disabledFields.includes("maritalStatus"),
      isMandatory: true,
    },
    {
      field: "numberSS",
      label: "Nº de Segurança Social",
      type: "number",
      disabled:
        ["ABROAD_COLLEGE_STUDENT", "WORKING_ABROAD"].includes(typeEmployee) ||
        disabledFields.includes("numberSS"),
    },
    {
      field: "inspectionId.name",
      label: "Entidade",
      type: "select",
      options: institution,
      fieldId: "inspectionId.id",
      disabled: disabledFields.includes("inspectionId"),
      hide: hideEntity,
      isMandatory: true,
    },
  ]);

  const [personalNationalDataCol1, setPersonalNationalDataCol1] = useState([
    {
      field: "bi",
      label: "BI",
      type: "text",
      onChange: (val) => {
        onBiChange(val);
      },
      disabled: disabledFields.includes("bi"),
      isMandatory: true,
    },
    {
      field: "name",
      label: "Nome",
      type: "text",
      disabled: disabledFields.includes("name"),
      isMandatory: true,
    },
    {
      field: "birthDate",
      label: "Data de Nascimento",
      type: "date",
      placeholder: "dd-MM-yyyy",
      dateFormat: "dd-MM-yyyy",
      disabled: disabledFields.includes("birthDate"),
      isMandatory: true,
    },
    {
      field: "phone",
      label: "Telefone",
      type: "text",
      disabled: disabledFields.includes("phone"),
      isMandatory: true,
    },
    {
      field: "nationality.name",
      fieldId: "nationality.id",
      label: "Nacionalidade",
      type: "auto-complete",
      alloptionscode: "/nationalities",
      optionscode: "/nationalities/suggestion",
      disabled: disabledFields.includes("nationality.name") || isNational,
      uniquevalue: isNational ? "Angola" : null,
      isMandatory: true,
    },
    {
      field: "genre",
      label: "Género",
      type: "select",
      options: [
        { name: "Masculino", cod: "MALE" },
        { name: "Feminino", cod: "FEMALE" },
      ],
      fieldId: "genre.cod",
      disabled: disabledFields.includes("genre"),
      isMandatory: true,
    },

    {
      field: "nif",
      label: "NIF",
      type: "text",
      disabled:
        ["ABROAD_COLLEGE_STUDENT", "WORKING_ABROAD"].includes(typeEmployee) ||
        disabledFields.includes("nif"),
      isMandatory: true,
    },
    {
      field: "agentNumber",
      label: "Nº de Agente",
      type: "number",
      disabled:
        ["ABROAD_COLLEGE_STUDENT", "WORKING_ABROAD", "PRIVATE_SECTOR"].includes(
          typeEmployee
        ) || disabledFields.includes("agentNumber"),
    },
    {
      field: "inspectionId.name",
      label: "Entidade",
      type: "select",
      options: institution,
      fieldId: "inspectionId.id",
      disabled: disabledFields.includes("inspectionId"),
      hide: hideEntity,
      isMandatory: true,
    },
  ]);

  const [personalNationalDataCol2, setPersonalNationalDataCol2] = useState([
    {
      field: "provinceOfBirth",
      label: "Província de Naturalidade 1",
      type: employeeData?.provinceOfBirth ? "text" : "select",
      options: fixEntityStructure(provinces),
      fieldId: "provinceOfBirth.name",
      onChange: getMunByProv,
      disabled: disabledFields.includes("provinceOfBirth"),
      returnValue: true,
      isMandatory: true,
    },
    {
      field: "municipalityOfBirth",
      label: "Município de Naturalidade",
      type: employeeData?.municipalityOfBirth ? "text" : "select",
      options: fixEntityStructure(municipalities),
      fieldId: "municipalityOfBirth.name",
      disabled: disabledFields.includes("municipalityOfBirth"),
      returnValue: true,
      isMandatory: true,
    },
    {
      field: "email",
      label: "Email",
      type: "email",
      disabled: disabledFields.includes("email"),
    },
    {
      field: "countryResidence.name",
      fieldId: "countryResidence.id",
      label: "País de Residência",
      type: "auto-complete",
      optionscode: "/countries/suggestion",
      alloptionscode: "/countries",
      
      disabled: disabledFields.includes("countryResidence.name"),
      isMandatory: true,
    },
    {
      field: "provinceResidence.name",
      label: "Província de Residência",
      type: "select",
      options: provinces,
      fieldId: "provinceResidence.id",
      disabled:
        ["ABROAD_COLLEGE_STUDENT", "WORKING_ABROAD"].includes(typeEmployee) ||
        disabledFields.includes("provinceResidence.name"),
      // hide: hideResidence,
      isMandatory: true,
    },
    {
      field: "address",
      label: "Morada",
      type: "text",
      disabled: disabledFields.includes("address"),
    },
    {
      field: "maritalStatus",
      label: "Estado Civil",
      type: "select",
      options: [
        { cod: "SINGLE", name: "Solteiro(a)" },
        { cod: "MARRIED", name: "Casado(a)" },
        { cod: "DIVORCED", name: "Divorciado(a)" },
        { cod: "WIDOW", name: "Viúvo(a)" },
      ],
      fieldId: "maritalStatus.cod",
      disabled: disabledFields.includes("maritalStatus"),
      isMandatory: true,
    },
    {
      field: "numberSS",
      label: "Nº de Segurança Social",
      type: "number",
      disabled:
        ["ABROAD_COLLEGE_STUDENT", "WORKING_ABROAD"].includes(typeEmployee) ||
        disabledFields.includes("numberSS"),
    },
  ]);

  const [personalDataCol1, setPersonalDataCol1] = useState(
    personalNationalDataCol1
  );

  const [personalDataCol2, setPersonalDataCol2] = useState(
    personalNationalDataCol2
  );

  useEffect(() => {
    if (provinces.length > 0 || municipalities.length > 0) {
      setPersonalNationalDataCol1([
        {
          field: "bi",
          label: "BI",
          type: "text",
          onChange: (val) => {
            onBiChange(val);
          },
          disabled: disabledFields.includes("bi"),
          isMandatory: true,
        },
        {
          field: "name",
          label: "Nome",
          type: "text",
          disabled: disabledFields.includes("name"),
          isMandatory: true,
        },
        {
          field: "birthDate",
          label: "Data de Nascimento",
          type: "date",
          placeholder: "dd-MM-yyyy",
          dateFormat: "dd-MM-yyyy",
          disabled: disabledFields.includes("birthDate"),
          isMandatory: true,
        },
        {
          field: "phone",
          label: "Telefone",
          type: "text",
          disabled: disabledFields.includes("phone"),
          isMandatory: true,
        },
        {
          field: "nationality.name",
          fieldId: "nationality.id",
          label: "Nacionalidade",
          type: "auto-complete",
          alloptionscode: "/nationalities",
          optionscode: "/nationalities/suggestion",
          disabled: disabledFields.includes("nationality.name") || isNational,
          isMandatory: true,
        },
        {
          field: "genre",
          label: "Género",
          type: "select",
          options: [
            { name: "Masculino", cod: "MALE" },
            { name: "Feminino", cod: "FEMALE" },
          ],
          fieldId: "genre.cod",
          disabled: disabledFields.includes("genre"),
          isMandatory: true,
        },

        {
          field: "nif",
          label: "NIF",
          type: "text",
          disabled:
            ["ABROAD_COLLEGE_STUDENT", "WORKING_ABROAD"].includes(
              typeEmployee
            ) || disabledFields.includes("nif"),
          isMandatory: true,
        },
        {
          field: "agentNumber",
          label: "Nº de Agente",
          type: "number",
          disabled:
            [
              "ABROAD_COLLEGE_STUDENT",
              "WORKING_ABROAD",
              "PRIVATE_SECTOR",
            ].includes(typeEmployee) || disabledFields.includes("agentNumber"),
        },
        {
          field: "inspectionId.name",
          label: "Entidade",
          type: "select",
          options: institution,
          fieldId: "inspectionId.id",
          disabled: disabledFields.includes("inspectionId"),
          hide: hideEntity,
          isMandatory: true,
        },
      ]);
      setPersonalNationalDataCol2([
        {
          field: "provinceOfBirth",
          label: "Província de Naturalidade",
          type: employeeData?.provinceOfBirth ? "text" : "select",
          options: fixEntityStructure(provinces),
          fieldId: "provinceOfBirth.name",
          onChange: getMunByProv,
          disabled: disabledFields.includes("provinceOfBirth"),
          returnValue: true,
          isMandatory: true,
        },
        {
          field: "municipalityOfBirth",
          label: "Município de Naturalidade",
          type: employeeData?.municipalityOfBirth ? "text" : "select",
          options: fixEntityStructure(municipalities),
          fieldId: "municipalityOfBirth.name",
          disabled: disabledFields.includes("municipalityOfBirth"),
          returnValue: true,
          isMandatory: true,
        },
        {
          field: "email",
          label: "Email",
          type: "email",
          disabled: disabledFields.includes("email"),
        },
        {
          field: "countryResidence.name",
          fieldId: "countryResidence.id",
          label: "País de Residência",
          type: "auto-complete",
          optionscode: "/countries/suggestion",
          alloptionscode: "/countries",
          
          disabled: disabledFields.includes("countryResidence.name"),
          isMandatory: true,
        },
        {
          field: "provinceResidence.name",
          label: "Província de Residência",
          type: "select",
          options: provinces,
          fieldId: "provinceResidence.id",
          disabled:
            ["ABROAD_COLLEGE_STUDENT", "WORKING_ABROAD"].includes(
              typeEmployee
            ) || disabledFields.includes("provinceResidence.name"),
          // hide: hideResidence,
          isMandatory: true,
        },
        {
          field: "address",
          label: "Morada",
          type: "text",
          disabled: disabledFields.includes("address"),
        },
        {
          field: "maritalStatus",
          label: "Estado Civil",
          type: "select",
          options: [
            { cod: "SINGLE", name: "Solteiro(a)" },
            { cod: "MARRIED", name: "Casado(a)" },
            { cod: "DIVORCED", name: "Divorciado(a)" },
            { cod: "WIDOW", name: "Viúvo(a)" },
          ],

          fieldId: "maritalStatus.cod",
          disabled: disabledFields.includes("maritalStatus"),
          isMandatory: true,
        },
        {
          field: "numberSS",
          label: "Nº de Segurança Social",
          type: "number",
          disabled:
            ["ABROAD_COLLEGE_STUDENT", "WORKING_ABROAD"].includes(
              typeEmployee
            ) || disabledFields.includes("numberSS"),
        },
      ]);
    }
  }, [provinces, municipalities]);

  useEffect(() => {
    if (isNational) {
      setPersonalDataCol2(personalNationalDataCol2);
    }
  }, [personalNationalDataCol2]);

  useEffect(() => {
    if (isNational) {
      setPersonalDataCol1(personalNationalDataCol1);
      setPersonalDataCol2(personalNationalDataCol2);
    } else {
      setPersonalDataCol1(personalForeignDataCol1);
      setPersonalDataCol2(personalForeignDataCol2);
    }
  }, [isNational]);

  const [personalDataEdit, setPersonalDataEdit] = useState([]);

  useEffect(() => {
    const personalNationalDataEdit = [
      {
        field: "bi",
        label: "BI",
        type: "text",
        onChange: (val) => {
          onBiChange(val);
        },
        disabled: disabledFields.includes("bi"),
        isMandatory: true,
        section: "Dados Pessoais",
        row: 1,
        // // width: "46%",
      },
      {
        field: "name",
        label: "Nome",
        type: "text",
        disabled: disabledFields.includes("name"),
        isMandatory: true,
        section: "Dados Pessoais",
        row: 2,
        // // width: "66%",
      },
      {
        field: "birthDate",
        label: "Data de Nascimento",
        type: "date",
        placeholder: "dd-MM-yyyy",
        dateFormat: "dd-MM-yyyy",
        minYears: null, // moment(new Date()).format("DD-MM-yyyy"),
        disabled: disabledFields.includes("birthDate"),
        isMandatory: true,
        section: "Dados Pessoais",
        row: 2,
        //// width: "29%",
      },
      {
        field: "genre",
        label: "Género",
        type: "select",
        options: [
          { name: "Masculino", cod: "MALE" },
          { name: "Feminino", cod: "FEMALE" },
        ],
        fieldId: "genre.cod",
        disabled: disabledFields.includes("genre"),
        isMandatory: true,
        section: "Dados Pessoais",
        row: 3,
      },
      {
        field: "maritalStatus",
        fieldId: "maritalStatus.cod",
        label: "Estado Civil",
        type: "select",
        options: [
          { cod: "SINGLE", name: "Solteiro(a)" },
          { cod: "MARRIED", name: "Casado(a)" },
          { cod: "DIVORCED", name: "Divorciado(a)" },
          { cod: "WIDOW", name: "Viúvo(a)" },
        ],
        disabled: disabledFields.includes("maritalStatus"),
        isMandatory: true,
        section: "Dados Pessoais",
        row: 1,
      },
      {
        field: "hasDisability",
        fieldId: "hasDisability.cod",
        label: "Possuí uma Deficiência?",
        type: "select",
        options: [
          { cod: true, name: "Sim" },
          { cod: false, name: "Não" },
        ],
        
        onChange: (val) => {
          if (val === "false") {
            setHideD(true);
          } else {
            setHideD(false);
          }
        },
        isMandatory: true,
        section: "Dados Pessoais",
        row: 1,
      },
      {
        field: "disability",
        fieldId: "disability.cod",
        label: "Qual destas?",
        type: "select",
        options: [
          { cod: "HEARING", name: "Auditiva" },
          { cod: "MENTAL", name: "Mental" },
          { cod: "VISUAL_IMPAIRMENT", name: "Visual" },
          { cod: "PHYSICAL", name: "Física" },
        ],
        disabled: disabledFields.includes("disability"),
        isMandatory: true,
        section: "Dados Pessoais",
        row: 1,
      },
      {
        field: "nationality.name",
        fieldId: "nationality.id",
        label: "Nacionalidade",
        type: "auto-complete",
        alloptionscode: "/nationalities",
        optionscode: "/nationalities/search",
        disabled: true,
        uniquevalue: isNational ? "Angola" : null,
        isMandatory: true,
        section: "Naturalidade",
        row: 1,
        // width: "46%",
      },
      
      {
        field: "provinceOfBirth.name",
        label: "Província de Naturalidade",
        type: "select",
        options: fixEntityStructure(provinces),
        fieldId: "provinceOfBirth.id",
        onChange: getMunByProv,
        disabled: disabledFields.includes("provinceOfBirth"),
        returnValue: true,
        isMandatory: true,
        section: "Naturalidade",
        row: 1,
        // width: "46%",
      },
      {
        field: "municipalityOfBirth",
        label: "Município de Naturalidade",
        ///employeeData?.municipalityOfBirth ? "text"
        type: "select",
        options: fixEntityStructure(municipalities),
        fieldId: "municipalityOfBirth.name",
        disabled: disabledFields.includes("municipalityOfBirth"),
        returnValue: true,
        isMandatory: true,
        section: "Naturalidade",
        row: 2,
        // width: "46%",
      },

      {
        field: "countryResidence.name",
        fieldId: "countryResidence.id",
        label: "País de Residência",
        type: "auto-complete",
        optionscode: "/countries/search",
        alloptionscode: "/countries",
        onchange: (val) => {
          if (val.name !== "Angola") {
            setHideDM(true);
          } else {
            setHideDM(false);
          }
        },
        // onchange: (val) => {
        //   // console.log("baba - ", val.name.toLowerCase())
        //   if (val.name.toLowerCase() === "angola") {
        //     // setHideResidence(false);
        //     setValueIndicative("+244");
        //   } else {
        //     setValueIndicative("");
        //     // setHideResidence(true);
        //   }
        // },
        disabled: disabledFields.includes("countryResidence.name"),
        isMandatory: true,
        section: "Localizacao",
        row: 1,
        // width: "46%",
      },
      {
        field: "provinceResidence.name",
        fieldId: "provinceResidence.id",
        label: "Província de Residência",
        type: "select",
        options: provinces,
        disabled: disabledFields.includes("provinceResidence.name"),
       //  hide: hideResidence,
        isMandatory: true,
        section: "Localizacao",
        row: 1,
        // width: "46%",
      },
      {
        field: "address",
        label: "Morada",
        type: "text",
        disabled: disabledFields.includes("address"),
        section: "Localizacao",
        // isMandatory: true,
        row: 1,
        // width: "47%",
      },
      {
        field: "currentLocation",
        label: "Morada atual",
        type: "text",
        isMandatory: true,
        disabled: disabledFields.includes("currentLocation"),
        section: "Localizacao",
        row: 1,
        // width: "47%",
      },
      {
        field: "indicative",
        label: "Indicativo",
        type: "text",
        disabled: disabledFields.includes("indicative"),
        isMandatory: true,
        // value: valueIndicative,
        section: "Contactos",
        row: 1,
        // width: "11%",
      },
      {
        field: "phone",
        label: "Telefone",
        type: "text",
        disabled: disabledFields.includes("phone"),
        isMandatory: true,
        section: "Contactos",
        row: 1,
        // width: "30%",
      },
      {
        field: "email",
        label: "Email",
        type: "email",
        disabled: disabledFields.includes("email"),
        section: "Contactos",
        row: 1,
        // width: "55%",
      },
      {
        field: "institution.name",
        label: "Entidade",
        type: "select",
        options: institution,
        fieldId: "institution.id",
        disabled: disabledFields.includes("institution.name"),
        isMandatory: true,
        section: "Entidade",
        row: 1,
        // width: "46%",
      },
    ];

    const personalForeignDataEdit = [
      {
        field: "foreignNationalityIdCard",
        label: "Tipo de Documento",
        type: "select",
        options: [
          { name: "Cartão de Residência", cod: "RESIDENCE_CARD" },
          { name: "Visto de trabalho", cod: "WORK_VISA" },
          { name: "Passaporte", cod: "PASSPORT" },
        ],
        fieldId: "foreignNationalityIdCard.cod",
        disabled: disabledFields.includes("foreignNationalityIdCard"),
        isMandatory: true,
        section: "Dados Pessoais",
        row: 1,
        // width: "46%",
      },
      {
        field: "bi",
        label: "Número do documento",
        type: "text",
        disabled: disabledFields.includes("bi"),
        isMandatory: true,
        section: "Dados Pessoais",
        row: 1,
        // width: "46%",
      },
      {
        field: "name",
        label: "Nome",
        type: "text",
        disabled: disabledFields.includes("name"),
        isMandatory: true,
        section: "Dados Pessoais",
        row: 2,
        // width: "66%",
      },
      {
        field: "birthDate",
        label: "Data de Nascimento",
        type: "date",
        placeholder: "dd-MM-yyyy",
        dateFormat: "dd-MM-yyyy",
        minYears: null, //moment(new Date()).format("DD-MM-yyyy"),
        disabled: disabledFields.includes("birthDate"),
        isMandatory: true,
        section: "Dados Pessoais",
        row: 2,
        // width: "29%",
      },
      {
        field: "genre",
        label: "Género",
        type: "select",
        options: [
          { name: "Masculino", cod: "MALE" },
          { name: "Feminino", cod: "FEMALE" },
        ],
        fieldId: "genre.cod",
        disabled: disabledFields.includes("genre"),
        isMandatory: true,
        section: "Dados Pessoais",
        row: 3,
        // width: "46%",
      },
      {
        field: "maritalStatus",
        label: "Estado Civil",
        type: "select",
        options: [
          { cod: "SINGLE", name: "Solteiro(a)" },
          { cod: "MARRIED", name: "Casado(a)" },
          { cod: "DIVORCED", name: "Divorciado(a)" },
          { cod: "WIDOW", name: "Viúvo(a)" },
        ],

        fieldId: "maritalStatus.cod",
        disabled: disabledFields.includes("maritalStatus"),
        isMandatory: true,
        section: "Dados Pessoais",
        row: 3,
        // width: "46%",
      },
      {
        field: "nationality.name",
        fieldId: "nationality.id",
        label: "Nacionalidade",
        type: "auto-complete",
        alloptionscode: "/nationalities",
        optionscode: "/nationalities/search",
        disabled: disabledFields.includes("nationality.name"),
        uniquevalue: isNational ? "Angola" : null,
        isMandatory: true,
        section: "Naturalidade",
        row: 1,
        // width: "46%",
      },
      {
        field: "countryResidence.name",
        fieldId: "countryResidence.id",
        label: "País de Residência",
        type: "auto-complete",
        optionscode: "/countries/search",
        alloptionscode: "/countries",
       
        disabled: disabledFields.includes("countryResidence.name"),
        isMandatory: true,
        section: "Localizacao",
        row: 1,
        // width: "46%",
      },
      {
        field: "provinceResidence.name",
        fieldId: "provinceResidence.id",
        label: "Província de Residência",
        type: "select",
        options: provinces,
        disabled: disabledFields.includes("provinceResidence.name"),
        isMandatory: true,
        section: "Localizacao",
        row: 1,
        // width: "46%",
        // hide: hideResidence,
      },
      {
        field: "address",
        label: "Morada",
        type: "text",
        disabled: disabledFields.includes("address"),
        section: "Localizacao",
        row: 1,
        // width: "47%",
      },
      {
        field: "indicative",
        label: "Indicativo",
        type: "text",
        disabled: disabledFields.includes("indicative"),
        isMandatory: true,
        // disabled: true,
        // value: "+244",
        section: "Contactos",
        row: 1,
        // width: "11%",
      },
      {
        field: "phone",
        label: "Telefone",
        type: "text",
        disabled: disabledFields.includes("phone"),
        isMandatory: true,
        section: "Contactos",
        row: 1,
        // width: "30%",
      },
      {
        field: "email",
        label: "Email",
        type: "email",
        disabled: disabledFields.includes("email"),
        section: "Contactos",
        row: 1,
        // width: "55%",
      },
      {
        field: "institution.name",
        label: "Entidade",
        type: "select",
        options: institution,
        fieldId: "institution.id",
        disabled: disabledFields.includes("institution.name"),
        isMandatory: true,
        section: "Entidade",
        row: 1,
        // width: "46%",
      },
    ];

    if (isNational) {
      return setPersonalDataEdit(personalNationalDataEdit);
    }
    return setPersonalDataEdit(personalForeignDataEdit);
  }, [
    isNational,
    disabledFields,
    provinces,
    municipalities,
    maritalStatus,
    institution,
    // hideResidence,
  ]);

  const [infoPessoaisValidation, setInfoPessoaisValidation] =
    useState(PublicAgentSchema);

  return {
    personalDataCol1,
    personalDataCol2,
    profilePicFieldMeta,
    nameFieldMeta,
    infoPessoaisValidation,
    personalDataEdit,
  };
};

export default InfoPessoaisMetaForm;
