import React, { useEffect, useRef, useState } from "react";
import { useField } from "@unform/core";
import { sub, parseISO } from "date-fns";
import { Label, LabelContainer } from "./Label";
import { ErrorMessage } from "./ErrorMessage";
import ReactDatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";
import "react-datepicker/dist/react-datepicker.css";
registerLocale("pt", pt);

const formatDate = (date) => {
  if (!date) return "";
  const noHoursDate = date.split(" ")[0];
  const hoursDate = date.split(" ")[1];

  const year = noHoursDate.split("-")[2];
  const month = noHoursDate.split("-")[1];
  const day = noHoursDate.split("-")[0];

  const seconds = hoursDate?.split(":")[2];
  const minutes = hoursDate?.split(":")[1];
  const hours = hoursDate?.split(":")[0];

  const dateNew = new Date(date + "T00:00");
  if (hoursDate === undefined) return dateNew;
  else
    return parseISO(`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`);
};

export default function DatePicker({
  name,
  label,
  isMandatory,
  minYears,
  maxYears,
  notEndDate,
  ...rest
}) {
  const datePickerRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [date, setDate] = useState(formatDate(defaultValue));

  useEffect(() => {
    setDate(formatDate(defaultValue));
  }, [defaultValue]);

  const subtractDate = (yearsAmount) => {
    return sub(new Date(), { years: yearsAmount });
  };

  let minYearConverted = null
  if(minYears !== null) {
    const splited = minYears ? minYears?.split("-") : "";
    minYearConverted = [splited?.[2], splited?.[1], splited?.[0]];
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datePickerRef,
      path: "props.selected",
      getValue: (ref) => {
        return ref.current.props.selected;
      },
      setValue: (ref, value) => {
        ref.current.props.selected = value;
      },
      clearValue: (ref) => {
        ref.current.clear();
      },
    });
  }, [fieldName, registerField]);

  return (
    <div>
      <LabelContainer>
        <Label htmlFor={fieldName}>{label}</Label>
        {isMandatory && <span style={{ color: "red" }}>*</span>}
      </LabelContainer>
      <div style={rest.style}>
        <ReactDatePicker
          showTimeSelect={rest.withHours}
          timeCaption="Horas"
          dateFormat={rest.dateFormat || "dd-MM-yyyy"}
          locale="pt"
          id={fieldName}
          ref={datePickerRef}
          selected={date}
          onChange={(date) => setDate(date)}
          minDate={new Date(minYearConverted)}
          maxDate={notEndDate === false ? !maxYears ? subtractDate(maxYears) : new Date(maxYears) : null}
          defaultValue={formatDate(defaultValue)}
          placeholderText={rest.placeholderText || "dd-MM-yyyy"}
          showDisabledMonthNavigation
          {...rest}
        />
      </div>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
}

// const styles = {
//   width: "98.155%",
// };
