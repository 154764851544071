import { useField } from "@unform/core";
import React, { useEffect, useRef, useState } from "react";
import { Label, LabelContainer } from "./Label";
import { ErrorMessage } from "./ErrorMessage";

export default function Select({
  name,
  label,
  subType,
  onChange = null,
  isMandatory,

  ...rest
}) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  console.log("VALUE: ", name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    selectRef.current.value = rest.defaultValue
      ? rest.defaultValue.id
      : defaultValue;
    //alert(defaultValue)
    if (rest.onLoad) rest.onLoad(defaultValue == false ? "false" : "true");
  }, [defaultValue, rest.defaultValue, rest.options]);

  const getDefaultValue = () => {
    // const option = rest.options?.find((option) => option.name === defaultValue);
    const lowerCaseDefault =
      typeof defaultValue === "string"
        ? defaultValue.toLowerCase()
        : defaultValue;

    console.log("lowerCaseDefault: ", lowerCaseDefault);
    console.log("default Value: ", defaultValue);

    const option =
      rest.options?.find((option) => {
        const lowerCaseName =
          typeof option.name === "string"
            ? option.name.toLowerCase()
            : "";


        const lowerCaseDefaultString = typeof lowerCaseDefault === "string" ? lowerCaseDefault.toLowerCase() : lowerCaseDefault;

        if (lowerCaseDefault) {
          return lowerCaseName.includes(lowerCaseDefaultString);
        } else {
          return false;
        }
      }) || [];

    console.log("Return value: ", rest.returnValue);
    if (option)
      return rest.returnValue
        ? option.name
        : option.id
          ? option.id
          : option.cod;
  };

  return (
    <div style={{ display: rest.hide ? "none" : "block" }}>
      <LabelContainer>
        <Label htmlFor={fieldName}>{label}</Label>
        {isMandatory && <span style={{ color: "red" }}>*</span>}
      </LabelContainer>

      <select
        id={fieldName}
        ref={selectRef}
        value={getDefaultValue()}
        //onChange={(e) => onChange(e)}
        onChange={onChange}
        {...rest}
      >
        <option value="null"> -- Selecione uma opção --</option>
        {rest.options &&
          rest.options.map((option, i) => (
            <option
              key={i}
              value={
                rest.returnValue
                  ? option.name
                  : option.id
                    ? option.id
                    : option.cod
              }
              selected={true}
            >
              {option.name}
            </option>
          ))}
        {subType === true && <option value="outros">Outros</option>}
      </select>

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
}

// const styles = {
//   background: "white",
//   width: "96%",
// };
