import {
  Box,
  Button,
  Grid,
  Modal,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import moment from "moment";
import { Form } from "@unform/web";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import AutoComplete from "../../../../components/Form/AutoComplete";
import Checkbox from "../../../../components/Form/Checkbox";
import DatePicker from "../../../../components/Form/DatePicker";
import Input from "../../../../components/Form/Input";
import Select from "../../../../components/Form/Select";
import ReactSelect from "../../../../components/Form/ReactSelect";

import AdminTitleHeader from "../../../components/AdminTitleHeader/AdminTitleHeader";
import HorizontalMenu from "../../../components/HorizonalMenu/HorizontalMenu";
import removeIconWhite from "../../../../assets/img/icons/deleteWhite.svg";
import uploadIcon from "../../../../assets/img/icons/upload.svg";
import downIcon from "../../../../assets/img/icons/downIcon.svg";
import useAlocacaoQuadros, {
  InformationIcon,
} from "../../../hooks/useAlocacaoQuadros";
import styles from "./alocacaoStyles.module.css";
import * as Yup from "yup";
import { styled } from "@material-ui/styles";
import KeycloakService from "../../../../services/KeycloakService";
import SideMenu from "../../../components/SideMenu/SideMenu";
import { useDispatch } from "react-redux";
import axios from "axios";
import { setSnackbar } from "../../../../store/reducers/admin/actionCreators";

const ModalContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: "#fff",
  margin: "20vh 50vh",
  borderRadius: "6px",
  // [theme.breakpoints.up("xxl")]: {
  //   height: "825px",
  // margin: "20vh 50vh",

  // },
  // [theme.breakpoints.down("xxl")]: {
  //   height: "775px",
  // margin: "20vh 50vh",

  // },
  // [theme.breakpoints.down("xl")]: {
  //   height: "675px",
  // margin: "20vh 50vh",

  // },
  // [theme.breakpoints.down("lg")]: {
  //   height: "605px",
  // margin: "20vh 50vh",

  // },
}));

const UpdateAllocationSchema = Yup.object().shape({
  employeeId: Yup.string().required("O id do quadro é obrigatório."),
  unit: Yup.object().shape({
    name: Yup.string().required("A unidade organica é obrigatória."),
  }),
  // obs: Yup.string().required("O motivo da exonerção é obrigatório."),
  endedOn: Yup.string().test(
    "isLengthValid",
    "A data do fim das funções é obrigatória.",
    (val) => {
      return val.length > 0;
    }
  ),
  startedOnNew: Yup.string().test(
    "isLengthValid",
    "A data do inicio de novas funções é obrigatória.",
    (val) => {
      return val.length > 0;
    }
  ),
  // institutionOfOrigin: Yup.object().shape({
  //   id: Yup.string().required("A entidade é obrigatória."),
  // }),
  position: Yup.object().shape({
    id: Yup.string().required("O cargo é obrigatório."),
  }),
  // category: Yup.object().shape({
  //   id: Yup.string(),
  //   name: Yup.string().when("id", {
  //     is: (id) => id === null || id === undefined || id === "",
  //     then: Yup.string().required("Deve indicar uma categoria já existente"),
  //   }),
  // }),
  // changeOfJuridicalBond: Yup.object().shape({
  //   id: Yup.string().required("A mudança da relação juridica é obrigatória."),
  // }),
  // contractualRegimen: Yup.object().shape({
  //   id: Yup.string().required("O regime de contrato é obrigatório."),
  // }),
  // juridicalBond: Yup.object().shape({
  //   id: Yup.string().required("A relação juridica é obrigatória."),
  // }),
  // agentNumber: Yup.string()
  //   .required("O Nº Agente é obrigatório.")
  //   .test("isLengthValid", "O Nº de Agente tem que ter 8 dígitos.", (val) => {
  //     return val.length === 8;
  //   }),
  // ssNumber: Yup.string().required("O Nº de Seg. Social é obrigatório."),
  // cifNumber: Yup.string().required("O código de identificação é obrigatório."),
  // consultant: Yup.boolean().required("Este campo é obrigatorio"),
  // startedOn: Yup.string().test(
  //   "isLengthValid",
  //   "A data do inicio das funções é obrigatória.",
  //   (val) => {
  //     return val.length > 0;
  //   }
  // ),
});

const MobilidadeQuadros = ({ match }) => {
  const visualizeOnly = match.params.operation === "ver";
  const formRef = useRef();
  const theme = useTheme();
  const history = useHistory();
  const [userInfo, setUserInfo] = useState();
  const dispatch = useDispatch();

  const getUserInfo = async () => {
    const response = await KeycloakService.getUserInfo();
    const { preferred_username } = response;
    setUserInfo(preferred_username);
  };

  console.log("Operation type: ", match.params.operation);

  const [action, setAction] = useState("add");
  const [file, setFile] = useState({});
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [horizontalMenuOption, setHorizontalMenuOption] =
    useState("alocacao_quadros");
  const [isLoading, setIsLoading] = useState(false);
  const [orgaos, setOrgaos] = useState([]);
  const [startedOnNew, setStartedOnNew] = useState("");

  const {
    unit,
    institutionEmployees,
    currentEmployee,
    fieldsMetaData,
    fixDate,
    fixAutoComplete,
    onEmployeeClicked,
    onAddValidationComplete,
    onEditValidationComplete,
    id,
    setSideTablePageIndex,
    institutionEmployeesTotalPages,
    query,
    setQuery,
    getResourceMobilityByEmployeeId,
    getResourceMobilityInfoByEmployeeId,
    clickedEmployee,
  } = useAlocacaoQuadros(
    match.params.institutionId,
    match.params.unitId,
    match.params.employeeId,
    "mobilidade"
  );

  useEffect(() => {
    if (match.params.employeeId) setAction("edit");

    if (!userInfo) {
      getUserInfo();
    }
  }, [match.params, userInfo]);

  const horizontalButtons = [
    {
      code: "alocacao_quadros",
      label: "Mobilidade de quadros",
      onClick: () => setHorizontalMenuOption("alocacao_quadros"),
    },
  ];

  const editAllocationValidation = (withFixedValues) => {
    var values = withFixedValues;
    console.log("whithFixedValues e isso: ", values);
    console.log("Employe data: ", currentEmployee);

    UpdateAllocationSchema.validate(
      {
        orgao: values.orgao,
        endedOn: values.endedOn,
        employeeId: match.params.employeeId,
        unit: { name: match.params.unitId },
        position: values.position,
        startedOnNew,
      },
      {
        abortEarly: false,
      }
    )
      .then((submitData) => {
        /*console.log('Form data: ', {
          employeeId: currentEmployee.id,
          unitId: match.params.unitId,
          date: values.endedOn,
          institutionId: currentEmployee.institutionOfOrigin.id,
          motive: "EXONERATED",
          obs: submitData.obs,
        })*/

        console.log("Testes: ", submitData);

        var agentNumber = currentEmployee.agentNumber
          ? currentEmployee.agentNumber
          : 0;
        var cifNumber = currentEmployee.cifNumber
          ? currentEmployee.cifNumber
          : 0;
        var ssNumber = currentEmployee.ssNumber ? currentEmployee.ssNumber : 0;

        axios
          .put(
            `${process.env.REACT_APP_API_URL}/resource-mobility/mobility`,
            {
              id: currentEmployee.id,
              employeeId: submitData.employeeId,
              unitId: submitData.orgao,
              startedOn: currentEmployee.startedOn,
              endedOn: submitData.endedOn,
              categoryId: currentEmployee.category.id ?? null,
              responsavel: userInfo,
              historicTypeId: 1,
              agentNumber: agentNumber,
              cifNumber: cifNumber,
              ssNumber: ssNumber,
              startedOnNew,
              // consultant: submitData.consultant,
              // changeOfJuridicalBondId: submitData.changeOfJuridicalBond.id,
              // contractualRegimenId: submitData.contractualRegimen.id,
              // juridicalBondId: submitData.juridicalBond.id,
              positionId: submitData.position.id,
              // institutionOfOriginId: submitData.institutionOfOrigin.id,
              // showNotification: true,
              // onSuccessMessage: "Alocação editada com sucesso",
            },
            {
              headers: {
                Authorization: `Bearer ${KeycloakService.getToken()}`,
              },
            }
          )
          .then((response) => {
            if (!response.data) return;
            dispatch(
              setSnackbar({
                snackbarIsOpen: true,
                snackbarMessage: "Mobilidade editada com sucesso",
                snackbarType: "success",
              })
            );
            history.goBack();
            // history.push(`/admin/instituicoes/${institutionId}/orgaos/${unitId}`);
          })
          .catch((error) => {
            console.log(error);
          });
        formRef.current.setErrors({});
      })
      .catch((error) => {
        console.log("schema is not valid: error", error);
        if (error instanceof Yup.ValidationError) {
          const errorMessages = {};
          error.inner.forEach((error) => {
            console.log(error.path, error.message);
            errorMessages[error.path] = error.message;
          });
          formRef.current.setErrors(errorMessages);
        }
      });
  };

  const handleEmployeeAllocationSubmit = (formData, { reset }) => {
    if (visualizeOnly) return;
    var withFixedValues = fixDate(fieldsMetaData, formData);
    withFixedValues = fixAutoComplete(fieldsMetaData, formData);

    if (action === "edit") {
      editAllocationValidation(withFixedValues);
    }
  };

  const margin = history.location.pathname.includes("admin") ? "" : "4rem 7rem";

  const ellipsisText = {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "100%",
  };

  const onRowClicked = (item, status) => {
    if (status === "ACTIVE") {
      getResourceMobilityInfoByEmployeeId(item.id);
      return setShowInfoModal(true);
    }
    onEmployeeClicked(item);
  };

  let minYearConverted;
  const splited = currentEmployee?.startedOn?.split("-");
  minYearConverted = splited?.[2] + "-" + splited?.[1] + "-" + splited?.[0];

  console.log("Current employee: ", currentEmployee);
  console.log("Current action: ", action);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/unities/${match.params.institutionId}?page=0&size=60&sort=name,asc`,
        {
          headers: {
            Authorization: `Bearer ${KeycloakService.getToken()}`,
          },
        }
      )
      .then((response) => {
        setOrgaos(response.data.content);
      });
  }, [match.params.institutionId]);

  function formatDateToYYYYMMDD(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Mês começa em 0
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  console.log(
    formatDateToYYYYMMDD(
      "Sun Aug 11 2024 00:00:00 GMT+0100 (West Africa Standard Time)"
    )
  );

  return (
    <Box sx={{ heigh: "100vh", display: "flex" }}>
      <SideMenu />
      <Box
        sx={{ display: "flex", flexDirection: "column", padding: "25px 50px" }}
        style={{ margin }}
      >
        <AdminTitleHeader title={"Mobilidade de quadros"} />
        <Box
          sx={{
            backgroundColor: "#fff",
            border: "1px solid #dfdfdf",
            borderBottom: "2px solid #dfdfdf",
            padding: "1rem 3rem",
            display: "flex",
          }}
        >
          <Box sx={{ width: "50%", display: "flex", gap: "2rem" }}>
            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                alignItems: "flex-end",
                color: theme.palette.primary.dark,
                fontWeight: "bold",
              }}
            >
              <span>Entidade</span>
              <span>Data de constituição</span>
            </Box>
            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                alignItems: "flex-start",
                color: "#3B4144",
              }}
            >
              <Tooltip title={unit?.institution?.name} placement="top">
                <span style={ellipsisText}>{unit?.institution?.name}</span>
              </Tooltip>
              <Tooltip
                title={unit?.institution?.constitutionDate}
                placement="top-start"
              >
                <span style={ellipsisText}>
                  {moment(unit?.institution?.constitutionDate).format(
                    "DD-MM-yyyy"
                  )}
                </span>
              </Tooltip>
            </Box>
          </Box>

          <Box sx={{ width: "50%", display: "flex", gap: "2rem" }}>
            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                alignItems: "flex-end",
                color: theme.palette.primary.dark,
                fontWeight: "bold",
              }}
            >
              <span>Orgão</span>
              <span>Pelouro</span>
            </Box>
            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                alignItems: "flex-start",
                color: "#3B4144",
              }}
            >
              <Tooltip title={unit?.name} placement="top">
                <span style={ellipsisText}>{unit?.name}</span>
              </Tooltip>
              <Tooltip title={unit?.expertiseName} placement="top">
                <span style={ellipsisText}>{unit?.expertiseName}</span>
              </Tooltip>
            </Box>
          </Box>
        </Box>

        <HorizontalMenu
          currentOption={horizontalMenuOption}
          options={horizontalButtons}
        />

        {/* PAGE CONTENT */}
        <Box
          sx={{
            backgroundColor: "#fff",
            padding: "2rem 1.5rem",
            display: "flex",
          }}
        >
          <Box
            sx={{
              flexBasis: `100%`,
            }}
          >
            <Form
              ref={formRef}
              onSubmit={handleEmployeeAllocationSubmit}
              initialData={currentEmployee}
              style={{ borderRadius: "unset", boxShadow: "none", padding: "0" }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row wrap",
                  gap: "1rem",
                  padding: 0,
                  margin: 0,
                }}
              >
                {fieldsMetaData.map((meta, i) => {
                  return meta?.title ? (
                    <Typography
                      variant="h4"
                      color="primary"
                      gutterBottom
                      sx={{
                        width: "100%",
                        fontWeight: "bold",
                      }}
                    >
                      {!["Licença", "Enquadramento"].includes(meta.title)
                        ? meta.title
                        : null}
                    </Typography>
                  ) : (
                    <Box>
                      {![
                        "select",
                        "checkbox",
                        "none",
                        "date",
                        "file",
                        "auto-complete",
                        "entity",
                      ].includes(meta.type) && (
                        <Input
                          key={i}
                          type={meta.type}
                          name={meta.field}
                          label={meta.label}
                          onChange={meta.onChange}
                          disabled={true}
                          isMandatory={
                            meta.field == "employee.bi"
                              ? false
                              : meta.isMandatory
                          }
                        />
                      )}
                      <Box>
                        {meta.field === "startedOn" || meta.field === "endedOn"
                          ? meta.type == "date" && (
                              <DatePicker
                                key={i}
                                type={meta.type}
                                name={meta.field}
                                label={meta.label}
                                disabled={
                                  meta.field === "startedOn" ? true : false
                                }
                                withHours={meta.withHours}
                                dateFormat={meta.dateFormat}
                                placeholder={meta.placeholder}
                                isMandatory={meta.isMandatory}
                                notEndDate={
                                  meta.field == "endOfLeave" ? true : false
                                }
                                minYears={moment(
                                  unit?.institution?.constitutionDate
                                ).format("DD-MM-yyyy")}
                                style={{ margin: "0 !important" }}
                              />
                            )
                          : null}

                        {["position.id", "unit.id"].includes(meta.field)
                          ? meta.type == "select" && (
                              <Select
                                key={i}
                                name={meta.field}
                                label={meta.label}
                                options={meta.options}
                                disabled={false}
                                isMandatory={meta.isMandatory}
                              />
                            )
                          : null}

                        {["position.id", "unit.id"].includes(meta.field)
                          ? meta.type == "entity" && (
                              <ReactSelect
                                key={i}
                                name={meta.field}
                                label={meta.label}
                                options={meta.options}
                                onChange={meta.onChange}
                                disabled={false}
                                isMandatory={meta.isMandatory}
                              />
                            )
                          : null}
                      </Box>
                    </Box>
                  );
                })}
                <div style={{ display: "flex", gap: "8px" }}>
                  <Select
                    name="orgao"
                    label="Órgão"
                    options={orgaos}
                    disabled={false}
                    isMandatory={true}
                  />

                  <DatePicker
                    type="date"
                    name="startedOnNew"
                    label="Início de novas funções"
                    dateFormat="dd-MM-yyyy"
                    isMandatory={true}
                    style={{ width: "100%" }}
                    minDate={new Date()}
                    onChange={(date) => {
                      const formattedDate = formatDateToYYYYMMDD(date);
                      setStartedOnNew(formattedDate);
                    }}
                    value={startedOnNew}
                  />
                </div>
              </Box>
              {!["ver"].includes(match.params.operation) && (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      gap: "1rem",
                      marginTop: "24px",
                    }}
                  >
                    <>
                      <Button
                        variant="outlined"
                        type="cancel"
                        onClick={(e) => {
                          e.preventDefault();
                          setAction(null);
                          history.goBack();
                        }}
                      >
                        Cancelar
                      </Button>
                      <Button type="submit" variant="contained">
                        Guardar
                      </Button>
                    </>
                  </Box>
                </Box>
              )}
            </Form>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MobilidadeQuadros;
