import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import styles from "./SideMenu.module.css";
import SideMenuJson from './SideMenu.json'

const SideMenu = () => {
  const history = useHistory();

  const [sidenavMenu, setSidenavMenu] = useState(SideMenuJson);

  const updateSidenavMenuView = (targetMenu) => {
    sidenavMenu.forEach((menu, index) => {
      if (menu == targetMenu) menu.displayChildren = !menu.displayChildren;
    });

    setSidenavMenu([...sidenavMenu]);
  };

  const updateRoute = (url, target) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    return !target ? history.push(url) : window.open(url, "_blank");
  };

  return (
    <>
      <div className={styles.sideMenu}>
        {sidenavMenu.map((menu, index) => {
          return (
            <div className={styles.menuSection} key={index}>
              <div className={styles.sideMenuSectionTitleContainer}>
                <span className={styles.sideMenuSectionTitle}>
                  {" "}
                  {menu.mainTitle}{" "}
                </span>
                <span className={styles.sideMenuSectionTitle}>
                  {menu.parentTitle}
                  <i
                    onClick={() => updateSidenavMenuView(menu)}
                    style={{
                      cursor: "pointer",
                      fontSize: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {menu.displayChildren ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <KeyboardArrowRightIcon />
                    )}
                  </i>
                </span>
              </div>

              <div className={styles.sideMenuSectionContainer}>
                {menu.displayChildren &&
                  menu.children.map((child, index) => {
                    return (
                      <div
                        className={styles.sideMenuSectionItemContainer}
                        key={index}
                        onClick={() => updateRoute(child.url, child.target)}
                      >
                        <span className={styles.sideMenuSectionItemName}>
                          {child.title}
                        </span>
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default SideMenu;
