import * as actionTypes from "./actionTypes";

const initialState = {
  employee: [],
  institution: {},
  institutions: [],
  institutionsPrivate: [],
  error: "",
  provinces: [],
  municipalities: [],
  disabilityTypes: [],
  naturalness: [],
  nationality: [],
  countries: [],
  schoolingCycles: [],
  maritalStatus: [],
  professions: [],
  professionalOrders: [],
  profilePicture: "",
  educationalInstitutions: [],
  activitySectors: [],
  institutionTypes: [],
  institutionType: [],
  institutionEmployees: [],
  politicalPositions: [],
  employeesToAssign: [],
  juridicalBonds: [],
  juridicalBondChange: [],
  units: [],
  unitEmployees: [],
  unitEmployeesTotalPages: 0,
  totalInstitutionEmployees: 0,
  course: null,
  courses: [],
  coursesByKnowledgeArea: [],
  knowledgeAreas: [],
  typeFormations: [],
  currentEmplyee: null,
  jobOffers: [],
  positions: [],
  categories: [],
  specialRegimeTypes: [],
  contractualRegimens: [],
  careers: [],
  isLoading: false,
  totalPages: 1,
  totalElements: 1,
  //
  snackbarIsOpen: false,
  snackbarMessage: "",
  snackbarType: "success",
  literaryQualifications: [],
  professionalExperiences: [],
  idioms: [],
  idiomas: [],
  idiomsByType: [],
  trainings: [],
  membershipProfessionalOrders: [],
  accomplishments: [],
  preferences: [],
  administrationFramings: [],
  volunteers: [],
  family: {},
  typesAccomplishments: [],
  typesVolunteers: [],
  familyKinship: [],
  employeeByBi: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_POLITICAL_POSITIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_POLITICAL_POSITIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        politicalPositions: action.payload,
      };
    case actionTypes.GET_POLITICAL_POSITIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        politicalPositions: [],
        error: action.payload.error,
      };

    case actionTypes.GET_EMPLOYEE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_EMPLOYEE_SECTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_EMPLOYEE_SECTION2_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_EMPLOYEE_SECTION3_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_EMPLOYEE_SECTION4_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_EMPLOYEE_SECTION5_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_EMPLOYEE_SECTION6_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_EMPLOYEE_SECTION7_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_EMPLOYEE_SECTION8_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_INSTITUTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.EDIT_EMPLOYEE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.EDIT_EMPLOYEE_SECTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.EDIT_INSTITUTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_PROVINCES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_NATIONALITY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_COUNTRIES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_IDIOMS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_SCHOOLINGCYCLE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_PROFILEPICTURE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_MARITALSTATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_EDUCATIONALINSTITUTIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_ACTIVITYSECTOR_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_INSTITUTIONTYPES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_INSTITUTIONTYPE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_INSTITUTIONEMPLOYEES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.SEARCH_INSTITUTIONEMPLOYEES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.FILTER_INSTITUTIONEMPLOYEES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    //
    case actionTypes.GET_EMPLOYEE_SUCCESS:
      return {
        ...state,
        employee: action.payload,
        isLoading: false,
      };
    case actionTypes.GET_EMPLOYEE_SECTION_SUCCESS:
      return {
        ...state,
        literaryQualifications: action.payload,
        isLoading: false,
      };
    case actionTypes.GET_EMPLOYEE_SECTION2_SUCCESS:
      return {
        ...state,
        professionalExperiences: action.payload,
        isLoading: false,
      };
    case actionTypes.GET_EMPLOYEE_SECTION3_SUCCESS:
      return {
        ...state,
        idiomas: action.payload,
        isLoading: false,
      };
    case actionTypes.GET_EMPLOYEE_SECTION4_SUCCESS:
      return {
        ...state,
        trainings: action.payload,
        isLoading: false,
      };
    case actionTypes.GET_EMPLOYEE_SECTION5_SUCCESS:
      return {
        ...state,
        volunteers: action.payload,
        isLoading: false,
      };
    case actionTypes.GET_EMPLOYEE_SECTION6_SUCCESS:
      return {
        ...state,
        accomplishments: action.payload,
        isLoading: false,
      };
    case actionTypes.GET_EMPLOYEE_SECTION7_SUCCESS:
      return {
        ...state,
        family: action.payload,
        isLoading: false,
      };
    case actionTypes.GET_EMPLOYEE_SECTION8_SUCCESS:
      return {
        ...state,
        administrationFramings: action.payload,
        isLoading: false,
      };
    case actionTypes.GET_INSTITUTION_SUCCESS:
      return {
        ...state,
        institution: action.payload,
        isLoading: false,
      };
    case actionTypes.EDIT_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.EDIT_INSTITUTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_PROVINCES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        provinces: action.payload,
      };
    case actionTypes.GET_NATIONALITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        nationality: action.payload,
      };
    case actionTypes.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        countries: action.payload,
      };
    case actionTypes.GET_IDIOMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        idioms: action.payload,
      };
    case actionTypes.GET_IDIOMS_BY_TYPE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        idiomsByType: action.payload,
      };
    case actionTypes.GET_COURSES_BY_KNOWLEDGE_AREA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        coursesByKnowledgeArea: action.payload,
      };
    case actionTypes.GET_SCHOOLINGCYCLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        schoolingCycles: action.payload,
      };
    case actionTypes.GET_PROFILEPICTURE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        profilePicture: action.payload,
      };
    case actionTypes.GET_MARITALSTATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        maritalStatus: action.payload,
      };
    case actionTypes.GET_EDUCATIONALINSTITUTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        educationalInstitutions: action.payload,
      };
    case actionTypes.GET_ACTIVITYSECTOR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        activitySectors: action.payload,
      };
    case actionTypes.GET_INSTITUTIONTYPES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        institutionTypes: action.payload,
      };
    case actionTypes.GET_INSTITUTIONTYPE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        institutionType: action.payload,
      };
    case actionTypes.GET_INSTITUTIONEMPLOYEES_SUCCESS:
      return {
        ...state,
        institutionEmployees: action.payload.content,
        totalInstitutionEmployees: action.payload.totalPages,
        courses: action.payload.allCourses,
        knowledgeAreas: action.payload.allKnowledgeAreas,
        isLoading: false,
      };
    case actionTypes.SEARCH_INSTITUTIONEMPLOYEES_SUCCESS:
      return {
        ...state,
        institutionEmployees: action.payload.data,
        totalInstitutionEmployees: action.payload.totalPages,
        isLoading: false,
      };
    case actionTypes.FILTER_INSTITUTIONEMPLOYEES_SUCCESS:
      return {
        ...state,
        institutionEmployees: action.payload.data,
        totalInstitutionEmployees: action.payload.totalPages,
        isLoading: false,
      };
    //
    case actionTypes.GET_EMPLOYEE_FAILURE:
      return {
        ...state,
        isLoading: false,
        employee: {},
        error: action.payload,
      };
    case actionTypes.GET_INSTITUTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        institution: {},
        error: action.payload,
      };
    case actionTypes.EDIT_EMPLOYEE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case actionTypes.EDIT_EMPLOYEE_SECTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case actionTypes.EDIT_INSTITUTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case actionTypes.GET_PROVINCES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case actionTypes.GET_NATIONALITY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case actionTypes.GET_COUNTRIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case actionTypes.GET_IDIOMS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case actionTypes.GET_IDIOMS_BY_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case actionTypes.GET_COURSES_BY_KNOWLEDGE_AREA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case actionTypes.GET_SCHOOLINGCYCLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case actionTypes.GET_PROFILEPICTURE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case actionTypes.GET_MARITALSTATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case actionTypes.GET_EDUCATIONALINSTITUTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case actionTypes.GET_ACTIVITYSECTOR_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case actionTypes.GET_INSTITUTIONTYPES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case actionTypes.GET_INSTITUTIONTYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case actionTypes.GET_INSTITUTIONEMPLOYEES_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.SEARCH_INSTITUTIONEMPLOYEES_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.FILTER_INSTITUTIONEMPLOYEES_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.GET_COURSES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.GET_COURSES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        courses: action.payload,
      };

    case actionTypes.GET_COURSES_FAILURE:
      return {
        ...state,
        isLoading: false,
        courses: [],
      };

    case actionTypes.GET_COURSE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.GET_COURSE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        course: action.payload,
      };

    case actionTypes.GET_COURSE_FAILURE:
      return {
        ...state,
        isLoading: false,
        course: null,
      };

    case actionTypes.GET_KNOWLEDGE_AREA_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.GET_KNOWLEDGE_AREA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        knowledgeAreas: action.payload,
      };

    case actionTypes.GET_KNOWLEDGE_AREA_FAILURE:
      return {
        ...state,
        isLoading: false,
        knowledgeAreas: [],
      };

    case actionTypes.GET_TYPE_FORMATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.GET_TYPE_FORMATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        typeFormations: action.payload,
      };

    case actionTypes.GET_TYPE_FORMATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        typeFormations: [],
      };

    case actionTypes.GET_PROFESSION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.GET_PROFESSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        professions: action.payload,
      };

    case actionTypes.GET_PROFESSION_FAILURE:
      return {
        ...state,
        isLoading: false,
        professions: [],
      };

    case actionTypes.GET_PROFESSIONAL_ORDERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.GET_PROFESSIONAL_ORDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        professionalOrders: action.payload,
      };

    case actionTypes.GET_PROFESSIONAL_ORDERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        professionalOrders: [],
      };

    case actionTypes.GETALL_INSTITUTIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.GETALL_INSTITUTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        institutions: action.payload,
      };

    case actionTypes.GETALL_INSTITUTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        institutions: [],
      };

    
      case actionTypes.GETALL_INSTITUTIONS_PRIVATE_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
  
      case actionTypes.GETALL_INSTITUTIONS_PRIVATE_SUCCESS:
        return {
          ...state,
          isLoading: false,
          institutions: action.payload,
        };
  
      case actionTypes.GETALL_INSTITUTIONS_PRIVATE_FAILURE:
        return {
          ...state,
          isLoading: false,
          institutions: [],
        };

    case actionTypes.GETALL_TYPES_VOLUNTEERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.GETALL_TYPES_VOLUNTEERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        typesVolunteers: action.payload,
      };

    case actionTypes.GETALL_TYPES_VOLUNTEERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        typesVolunteers: [],
      };

    case actionTypes.GETALL_TYPES_ACCOMPLISHMENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.GETALL_TYPES_ACCOMPLISHMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        typesAccomplishments: action.payload,
      };

    case actionTypes.GETALL_TYPES_ACCOMPLISHMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        typesAccomplishments: [],
      };

    case actionTypes.GET_JOB_OFFERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        jobOffers: [],
        totalPages: 0,
      };

    case actionTypes.GET_JOB_OFFERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        jobOffers: action.payload.data,
        totalPages: action.payload.totalPages,
      };

    case actionTypes.GET_JOB_OFFERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        jobOffers: [],
        totalPages: 0,
      };

    case actionTypes.SET_MUNICIPALITIES:
      return {
        ...state,
        isLoading: true,
        municipalities: [],
      };

    case actionTypes.GET_MUNICIPALITIES_REQUEST:
      return {
        ...state,
        isLoading: true,
        municipalities: [],
      };

    case actionTypes.GET_MUNICIPALITIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        municipalities: action.payload,
      };

    case actionTypes.GET_MUNICIPALITIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        municipalities: [],
      };

    // ------------------------------------------------------
    case actionTypes.SET_DISABILITYTYPES:
      return {
        ...state,
        isLoading: true,
        disabilityTypes: [],
      };

    case actionTypes.GET_DISABILITYTYPES_REQUEST:
      return {
        ...state,
        isLoading: true,
        disabilityTypes: [],
      };

    case actionTypes.GET_DISABILITYTYPES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        disabilityTypes: action.payload,
      };

    case actionTypes.GET_DISABILITYTYPES_FAILURE:
      return {
        ...state,
        isLoading: false,
        disabilityTypes: [],
      };

    case actionTypes.GET_PROVINCES_MUNICIPALITIES_REQUEST:
      return {
        ...state,
        // isLoading: true,
        municipalities: [],
      };

    case actionTypes.GET_PROVINCES_MUNICIPALITIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        municipalities: action.payload,
      };

    case actionTypes.GET_PROVINCES_MUNICIPALITIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        municipalities: [],
      };

    case actionTypes.GET_POSITIONS_REQUEST:
      return {
        ...state,
        // isLoading: true,
        positions: [],
      };

    case actionTypes.GET_POSITIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        positions: action.payload,
      };

    case actionTypes.GET_POSITIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        positions: [],
      };

    case actionTypes.GET_CATEGORIES_REQUEST:
      return {
        ...state,
        // isLoading: true,
        categories: [],
      };

    case actionTypes.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categories: action.payload,
      };

    case actionTypes.GET_CATEGORIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        categories: [],
      };

    case actionTypes.GET_SPECIALREGIMETYPES_REQUEST:
      return {
        ...state,
        // isLoading: true,
        specialRegimeTypes: [],
      };

    case actionTypes.GET_SPECIALREGIMETYPES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        specialRegimeTypes: action.payload,
      };

    case actionTypes.GET_SPECIALREGIMETYPES_FAILURE:
      return {
        ...state,
        isLoading: false,
        specialRegimeTypes: [],
      };

    case actionTypes.GET_CAREERS_REQUEST:
      return {
        ...state,
        // isLoading: true,
        careers: [],
      };

    case actionTypes.GET_CAREERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        careers: action.payload,
      };

    case actionTypes.GET_CAREERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        careers: [],
      };
    case actionTypes.GET_EMPLOYEESTOASSIGN_REQUEST:
      return {
        ...state,
        // isLoading: true,
        employeesToAssign: [],
      };

    case actionTypes.GET_EMPLOYEESTOASSIGN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        employeesToAssign: action.payload,
      };

    case actionTypes.GET_EMPLOYEESTOASSIGN_FAILURE:
      return {
        ...state,
        isLoading: false,
        employeesToAssign: [],
      };
    case actionTypes.GET_UNIT_REQUEST:
      return {
        ...state,
        // isLoading: true,
        unit: [],
      };

    case actionTypes.GET_UNIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        unit: action.payload,
      };

    case actionTypes.GET_UNIT_FAILURE:
      return {
        ...state,
        isLoading: false,
        unit: [],
      };
    case actionTypes.GET_MUNICIPALITIES_COMMUNES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        communes: action.payload,
      };

    case actionTypes.GET_MUNICIPALITIES_COMMUNES_FAILURE:
      return {
        ...state,
        isLoading: false,
        communes: [],
      };

    case actionTypes.GET_CONTRACTUALREGIMENSTYPES_REQUEST:
      return {
        ...state,
        // isLoading: true,
        contractualRegimens: [],
      };

    case actionTypes.GET_CONTRACTUALREGIMENSTYPES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        contractualRegimens: action.payload,
        totalContractualRegimensPages: action.payload.totalPages,
      };

    case actionTypes.GET_CONTRACTUALREGIMENSTYPES_FAILURE:
      return {
        ...state,
        isLoading: false,
        contractualRegimens: [],
      };

    case actionTypes.GET_JURIDICALBONDS_REQUEST:
      return {
        ...state,
        // isLoading: true,
        juridicalBonds: [],
      };

    case actionTypes.GET_JURIDICALBONDS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        juridicalBonds: action.payload,
        totalJuridicalBondsPages: action.payload.totalPages,
      };

    case actionTypes.GET_JURIDICALBONDS_FAILURE:
      return {
        ...state,
        isLoading: false,
        juridicalBonds: [],
      };

    case actionTypes.GET_JURIDICALBONDCHANGE_REQUEST:
      return {
        ...state,
        // isLoading: true,
        juridicalBondChange: [],
      };

    case actionTypes.GET_JURIDICALBONDCHANGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        juridicalBondChange: action.payload,
        totalJuridicalBondChangePages: action.payload.totalPages,
      };

    case actionTypes.GET_JURIDICALBONDCHANGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        juridicalBondChange: [],
      };

    case actionTypes.GET_UNITEMPLOYEES_REQUEST:
      return {
        ...state,
        // isLoading: true,
        unitEmployees: [],
      };

    case actionTypes.GET_UNITEMPLOYEES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        unitEmployees: action.payload.data,
        unitEmployeesTotalPages: action.payload.totalPages || 0,
      };

    case actionTypes.GET_UNITEMPLOYEES_FAILURE:
      return {
        ...state,
        isLoading: false,
        unitEmployees: [],
      };

    case actionTypes.GET_FAMILYKINSHIP_REQUEST:
      return {
        ...state,
        // isLoading: true,
        familyKinship: [],
      };

    case actionTypes.GET_FAMILYKINSHIP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        familyKinship: action.payload,
        totalFamilyKinshipPages: action.payload.totalPages,
      };

    case actionTypes.GET_FAMILYKINSHIP_FAILURE:
      return {
        ...state,
        isLoading: false,
        familyKinship: [],
      };

    case actionTypes.GET_USERBYBI_REQUEST:
      return {
        ...state,
        isLoading: true,
        employeeByBi: null,
      };

    case actionTypes.GET_USERBYBI_SUCCESS:
      return {
        ...state,
        isLoading: false,
        employeeByBi: action.payload,
      };

    case actionTypes.GET_USERBYBI_FAILURE:
      return {
        ...state,
        isLoading: false,
        employeeByBi: null,
      };

    case actionTypes.SET_SNACKBAR:
      return {
        ...state,
        snackbarType: action.payload.snackbarType,
        snackbarMessage: action.payload.snackbarMessage,
        snackbarIsOpen: action.payload.snackbarIsOpen,
      };

    case actionTypes.SET_ISLOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
